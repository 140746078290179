import Layout from "../components/Layout";
import { Center, Text, Divider, VStack } from "@chakra-ui/react";
import Anchor from "../components/Anchor";
import { SEO } from "../components/SEO";

import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout blurBackground>
      <SEO title={t("common.not_found")} />
      <Center w="full" h="full">
        <VStack maxW="lg" spacing={3} align="left">
          <Text fontSize="5xl">{t("common.not_found")}</Text>
          <Text fontSize="md">{t("common.could_not_find_page")}</Text>
          <Divider />

          <Anchor to="/">
            ...
            {t("common.go_back_to_home")}
          </Anchor>
        </VStack>
      </Center>
    </Layout>
  );
};

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
