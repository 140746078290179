import { Link as GatsbyLink } from "gatsby";
import { Link } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
type Props = {
  to: string;
  target?: string;
  children: ReactNode;
};

const Anchor: FC<Props> = ({ to, target, children }) => {
  return (
    <Link to={to} as={GatsbyLink} target={target}>
      {children}
    </Link>
  );
};

export default Anchor;
